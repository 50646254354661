import { Segment, getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { getBlob, getJson } from "@/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  keepPreviousData,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";

export type PerformanceData = {
  accountNumber: 0;
  pdfDate: Date;
  managerName: string;
  shortName: string;
  fileName: string;
  fileId: string;
};

type PerformanceResult = {
  pageNumber: number;
  pageSize: number;
  totalRecordCount: number;
  totalPageCount: number;
  performanceReportsData: PerformanceData[];
};

type PerformanceParams = {
  advisoryPerformance: boolean;
  lastBusinessDay: boolean;
  acctNumbers: string[];
};

export type Disclosures = {
  superscript: number;
  description: string;
};

type Period = {
  rateOfReturn: number | null;
  valuationPeriodStartDate: Date | null;
  valuationPeriodEndDate: Date;

  disclosureTypeCodes: Disclosures[];

  valuationPeriodEndingValue: number | null;
};

type IndexPerformance = {
  benchmarkId: string;
  benchmarkDescription: string;
  rateOfReturnAsOfDate: Date;

  fiveYear: Period;
  threeYear: Period;
  oneYear: Period;
  month: Period;
  ytd: Period;
};

export type PortfolioDisclosure = {
  id: number;
  desc: string;
};

export type PortfolioPeriod = {
  percentReturn: number | null;
  startDate: Date | null;
  endDate: Date | null;
  beginValue: number | null;
  endValue: number | null;
  changeValue: number | null;
  netContributionValue: number | null;
  disclosures: PortfolioDisclosure[];
};

export type PortfolioPerformance = {
  asOfDate: Date;

  fiveYear: PortfolioPeriod;
  threeYear: PortfolioPeriod;
  oneYear: PortfolioPeriod;
  month: PortfolioPeriod;
  ytd: PortfolioPeriod;
  mtd: PortfolioPeriod;
  sinceInception: PortfolioPeriod;
};

export type AccountPeriod = {
  rateOfReturn: number | null;
  valuationPeriodStartDate: Date | null;
  valuationPeriodEndDate: Date | null;

  disclosureTypeCodes: Disclosures[];
  valuationPeriodEndingValue: number | null;
};

export type AnnuityPerformance = {
  annuityContractNumber: string;
  rateOfReturnAsOfDate: Date;
  fiveYear: AccountPeriod | null;
  threeYear: AccountPeriod | null;
  oneYear: AccountPeriod | null;
  sinceInception: AccountPeriod | null;
  mtd: AccountPeriod | null;
  ytd: AccountPeriod | null;
};

export type AccountPerformance = {
  accountNumber: number;
  accountName: string;
  accountOpenDate: Date | null;
  rateOfReturnAsOfMonthEnd: Date | null;
  rateOfReturnAsOfDate: Date | null;
  rateOfReturnAsOfCurrent: Date | null;
  isMutualFundLevel4: boolean;
  fiveYear: AccountPeriod | null;
  threeYear: AccountPeriod | null;
  oneYear: AccountPeriod | null;
  sinceInception: AccountPeriod | null;
  mtd: AccountPeriod | null;
  ytd: AccountPeriod | null;
  annuities: AnnuityPerformance[];
  hasNoManager: boolean;
  isInManagedPlanAsOfReportDate: boolean;
};

export enum Method {
  IRR = "IRR",
  DTWR = "DTWR",
}

type CalculationMethod = {
  method: Method;
  effectiveDate: Date;
};

type IsAdvisory = {
  accountNum: string;
  isAdvisory: boolean;
};

const GATEWAY_PATH = "performancereports/v1";

export async function hasApl(): Promise<boolean> {
  return await getJson<boolean>(`/${GATEWAY_PATH}/Performance/APL/Exists`);
}

export async function getAplPerformanceData({
  pageNumber,
  pageSize,
  quarter,
  year,
}: {
  pageNumber?: number | null;
  pageSize?: number | null;
  quarter?: number | null;
  year?: number | null;
}): Promise<PerformanceResult> {
  const params = new URLSearchParams({ pageNumber: "1", pageSize: "1000" });
  if (
    typeof pageNumber === "number" &&
    Number.isFinite(pageNumber) &&
    Number.isInteger(pageNumber) &&
    pageNumber > 1
  ) {
    params.set("pageNumber", encodeURIComponent(pageNumber));
  }
  if (
    typeof pageSize === "number" &&
    Number.isFinite(pageSize) &&
    Number.isInteger(pageSize) &&
    pageSize > 1
  ) {
    params.set("pageSize", encodeURIComponent(pageSize));
  }
  if (quarter) {
    params.set("quarter", encodeURIComponent(quarter));
  }
  if (year) {
    params.set("year", encodeURIComponent(year));
  }
  return await getJson<PerformanceResult>(
    `/${GATEWAY_PATH}/Performance/APL/PerformanceData?${params.toString()}`,
  );
}

export async function getAplPerformancePdf(fileId: string): Promise<Blob> {
  return await getBlob(
    `/${GATEWAY_PATH}/Performance/APL/PerformancePDF?fileId=${encodeURIComponent(fileId)}`,
  );
}

export const performanceReportsQueryKeys = createQueryKeys("Performance", {
  aplExists: {
    queryKey: null,
    queryFn: hasApl,
  },
  aplPerformanceData: ({
    pageNumber,
    pageSize,
    quarter,
    year,
  }: {
    pageNumber?: number | null;
    pageSize?: number | null;
    quarter?: number | null;
    year?: number | null;
  }) => ({
    queryKey: [{ pageNumber, pageSize, quarter, year }],
    queryFn: () =>
      getAplPerformanceData({
        pageNumber,
        pageSize,
        quarter,
        year,
      }),
  }),
  aplPerformancePdf: (fileId: string) => ({
    queryKey: [fileId],
    queryFn: () => getAplPerformancePdf(fileId),
  }),
});

export function useAplPerformanceData({
  pageNumber,
  pageSize,
  quarter,
  year,
}: {
  pageNumber?: number | null;
  pageSize?: number | null;
  quarter?: number | null;
  year?: number | null;
}) {
  return useQuery({
    ...performanceReportsQueryKeys.aplPerformanceData({
      pageNumber,
      pageSize,
      quarter,
      year,
    }),
    placeholderData: keepPreviousData,
  });
}

export function useAplPerformancePdf(fileId: string) {
  return useQuery(performanceReportsQueryKeys.aplPerformancePdf(fileId));
}

export function useAplExists() {
  return useQuery(performanceReportsQueryKeys.aplExists);
}

export function useHasAplQueryOptions() {
  const jwt = useJwt();
  const isEnabled =
    jwt.requiredActivities.length == 0 &&
    [Segment.IndividualInvestors, Segment.PrivateAssetManagement].includes(
      jwt.segment,
    );
  const defaultData = false;
  return queryOptions({
    queryKey: performanceReportsQueryKeys.aplExists.queryKey,
    queryFn: (context) =>
      isEnabled
        ? (
          performanceReportsQueryKeys.aplExists.queryFn(
            context,
          ) as Promise<boolean>
        ).catch((_) => defaultData)
        : defaultData,
    staleTime: isEnabled ? Infinity : 0,
    gcTime: isEnabled ? 0 : undefined,
    throwOnError: false,
  });
}

export async function getIndexPerformance(): Promise<IndexPerformance[]> {
  return await getJson<IndexPerformance[]>(
    `/${GATEWAY_PATH}/Performance/Index`,
  );
}

export async function getPortfolioPerformance({
  advisoryPerformance,
  lastBusinessDay,
  acctNumbers,
}: PerformanceParams): Promise<PortfolioPerformance> {
  const jwt = getCurrentJwt();
  const acntIdxs = jwt.getAccountIndex(acctNumbers);
  const acntIdxsParam = acntIdxs.map(encodeURIComponent).join(",");
  const advisoryPerformanceStr = encodeURIComponent(advisoryPerformance);
  const lastBusinessDayStr = encodeURIComponent(lastBusinessDay);

  return await getJson<PortfolioPerformance>(
    `/${GATEWAY_PATH}/Performance/Portfolio?advisoryPerformance=${advisoryPerformanceStr}&lastBusinessDay=${lastBusinessDayStr}&acctIndex=${acntIdxsParam}`,
  );
}

export async function getAccountPerformance({
  advisoryPerformance,
  lastBusinessDay,
  acctNumbers,
}: PerformanceParams): Promise<AccountPerformance[]> {
  const jwt = getCurrentJwt();
  const acntIdxs = jwt.getAccountIndex(acctNumbers);
  const acntIdxsParam = acntIdxs.map(encodeURIComponent).join(",");
  const advisoryPerformanceStr = encodeURIComponent(advisoryPerformance);
  const lastBusinessDayStr = encodeURIComponent(lastBusinessDay);

  return await getJson<AccountPerformance[]>(
    `/${GATEWAY_PATH}/Performance/Account?advisoryPerformance=${advisoryPerformanceStr}&lastBusinessDay=${lastBusinessDayStr}&acctIndex=${acntIdxsParam}`,
  );
}

export async function getCalculationMethod(): Promise<CalculationMethod> {
  return await getJson<CalculationMethod>(
    `/${GATEWAY_PATH}/Performance/CalculationMethod`,
  );
}

export async function isAdvisory(acctNumbers: string[]): Promise<IsAdvisory[]> {
  const jwt = getCurrentJwt();
  const acntIdxs = jwt.getAccountIndex(acctNumbers);
  const queryParam = acntIdxs.map(encodeURIComponent).join(",");
  return await getJson<IsAdvisory[]>(
    `/${GATEWAY_PATH}/Performance/IsAdvisory?accountIndexes=${queryParam}`,
  );
}
